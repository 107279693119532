import React from 'react';
import * as subActions from '../actions/ActionTypes/SubscriptionTypes';
const initialState = {loading:false,subscription:'',error:false ,errorMsg:''};

const SubscriptionReducer = (state = initialState , action )=>{

  switch (action.type) {

    case subActions.SUBSCRIPTION_REQUEST:
      return {
        ...state ,loading: true , error: false ,errorMsg:''
      }
    case subActions.SUBSCRIPTION_SUCCESS:
      return {
        ...state ,loading: false , error: false ,errorMsg:''
      }
    case subActions.SUBSCRIPTION_FAIL:
      return {
        ...state ,loading: false , error: false ,errorMsg:action.msg
      }

    case subActions.SUBSCRIPTION_FETCH_REQUEST:
      return {
        ...state ,loading: true , error: false ,errorMsg:'',subscription:''
      }
    case subActions.SUBSCRIPTION_FETCH_SUCCESS:
      return {
        ...state ,loading: false , error: false ,errorMsg:'' , subscription:action.subscription
      }
    case subActions.SUBSCRIPTION_FETCH_FAIL:
      return {
        ...state ,loading: false , error: false ,errorMsg:action.msg,subscription:''
      }

    default:
      return state;
  }
}
export default SubscriptionReducer;
