import ChangeStateReducer from './ChangeStateReducer'
import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import sessionStorage from 'redux-persist/lib/storage/session'
import UserReducer from "./UserReducer";
import AuthReducer from "./AuthReducer";
import ProductReducer from "./ProductsReducer";
import OrderReducer from "./OrderReducer";
import ProductUploadsReducer from "./ProductUploadsReducer";
import CategoryReducer from "./CategoryReducer";
import CartReducer from "./CartReducer";
import StockReducer from "./StockReducer";
import RegisterReducer from "./RegisterReducer";
import UploadedFilesReducer from "./UploadedFilesReducer";
import SettingsReducer from "./SettingsReducer";
import SubscriptionReducer from "./SubscriptionReducer"
import DashboardReducer from "./DashboardReducer";
import ActiveInactiveReducer from "./ActiveInactiveRuducer";
const persistConfig = {
  key: 'root',
  storage :sessionStorage,
  // whitlelist:['users','deposit','expenses','auth','home']
}

const appReducer = combineReducers({
  sidebar: ChangeStateReducer,
  users:UserReducer,
  widget_auth:AuthReducer,
  products:ProductReducer,
  orders:OrderReducer,
  uploads:ProductUploadsReducer,
  categories:CategoryReducer,
  cart :CartReducer,
  stock :StockReducer,
  register:RegisterReducer,
  uploadedFiles:UploadedFilesReducer,
  settings:SettingsReducer,
  subscription:SubscriptionReducer,
  dashboard: DashboardReducer,
  activeInactive: ActiveInactiveReducer
});

const combinedReducer = (state,action) => {
  if(action.type=='USER_LOGOUT'){
    sessionStorage.removeItem("persist:root");
    state = undefined;
   // console.log("Here")

  }
  return appReducer(state,action)

}

const RootReducer = persistReducer(persistConfig,combinedReducer);
export default RootReducer;
