import React from 'react';
import * as settingActions from '../actions/ActionTypes/SettingsType';
const initialState = {loading:false,settings:[],error:false ,errorMsg:'',customerId:''};

const SettingsReducer = (state = initialState , action )=>{

  switch (action.type) {

    case settingActions.SETTINGS_UPDATE_REQUEST:
      return {
        ...state ,loading: true, settings:[] , error: false ,errorMsg:''
      }
    case settingActions.SETTINGS_UPDATE_SUCCESS:
      return {
        ...state ,loading: false, settings:action.settings , error: false ,errorMsg:''
      }
    case settingActions.SETTINGS_UPDATE_FAIL:
      return {
        ...state ,loading: false, settings:[] , error: false ,errorMsg:action.msg
      }
    case 'CHANGE_SETTING_VALUE':
      const rowIndex = state.settings.findIndex(row => row.id === action.id);
      const modified = state.settings;
      modified[rowIndex]['value'] = action.value;
      return {...state ,settings: modified}
    default:
      return state;
  }
}

export default SettingsReducer;
