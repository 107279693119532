export const STOCK_FETCH_REQUEST = 'STOCK_FETCH_REQUEST';
export const STOCK_FETCH_SUCCESS = 'STOCK_FETCH_SUCCESS';
export const STOCK_FETCH_FAIL = 'STOCK_FETCH_FAIL';

export const STOCK_ADD_REQUEST = 'STOCK_ADD_REQUEST';
export const STOCK_ADD_SUCCESS = 'STOCK_ADD_SUCCESS';
export const STOCK_ADD_FAIL = 'STOCK_ADD_FAIL';

export const STOCK_UPDATE_REQUEST = 'STOCK_UPDATE_REQUEST';
export const STOCK_UPDATE_SUCCESS = 'STOCK_UPDATE_SUCCESS';
export const STOCK_UPDATE_FAIL = 'STOCK_UPDATE_FAIL';

export const STOCK_DELETE_REQUEST = 'STOCK_DELETE_REQUEST';
export const STOCK_DELETE_SUCCESS = 'STOCK_DELETE_SUCCESS';
export const STOCK_DELETE_FAIL = 'STOCK_DELETE_FAIL';


