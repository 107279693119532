export const CATEGORY_FETCH_REQUEST = 'CATEGORY_FETCH_REQUEST';
export const CATEGORY_FETCH_SUCCESS = 'CATEGORY_FETCH_SUCCESS';
export const CATEGORY_FETCH_FAIL = 'CATEGORY_FETCH_FAIL';

export const CATEGORY_ADD_REQUEST = 'CATEGORY_ADD_REQUEST';
export const CATEGORY_ADD_SUCCESS = 'ADD_USER';
export const CATEGORY_ADD_FAIL = 'CATEGORY_ADD_FAIL';

export const CATEGORY_UPDATE_REQUEST = 'CATEGORY_UPDATE_REQUEST';
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS';
export const CATEGORY_UPDATE_FAIL = 'CATEGORY_UPDATE_FAIL';

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST';
export const CATEGORY_DELETE_SUCCESS = 'DELETE_USER';
export const CATEGORY_DELETE_FAIL = 'CATEGORY_DELETE_FAIL';


