
export const FETCH_PRODUCTS_COUNT = 'FETCH_PRODUCTS_COUNT';
export const SET_PRODUCTS_COUNT = 'SET_PRODUCTS_COUNT';
export const SET_PRODUCTS_FAIL = 'SET_PRODUCTS_FAIL';

export const FETCH_VIEWS_COUNT = 'FETCH_VIEWS_COUNT';
export const SET_VIEWS_COUNT = 'SET_VIEWS_COUNT';
export const SET_VIEWS_FAIL = 'SET_VIEWS_FAIL';

export const FETCH_ACTIVE_PRODUCTS_COUNT = 'FETCH_ACTIVE_PRODUCTS';
export const SET_ACTIVE_PRODUCTS_COUNT = 'SET_ACTIVE_PRODUCTS_COUNT';
export const SET_ACTIVE_PRODUCTS_FAIL = 'SET_ACTIVE_PRODUCTS_FAIL';

export const FETCH_MOST_VIEWED_PRODUCTS_COUNT = 'FETCH_MOST_VIEWED_PRODUCTS_COUNT';
export const SET_MOST_VIEWED_PRODUCTS_COUNT = 'SET_MOST_VIEWED_PRODUCTS_COUNT';
export const SET_MOST_VIEWED_PRODUCTS_FAIL = 'SET_MOST_VIEWED_PRODUCTS_FAIL';
