export const ORDER_FETCH_REQUEST = 'ORDER_FETCH_REQUEST';
export const ORDER_FETCH_SUCCESS = 'ORDER_FETCH_SUCCESS';
export const ORDER_FETCH_FAIL = 'ORDER_FETCH_FAIL';

export const ORDER_ADD_REQUEST = 'ORDER_ADD_REQUEST';
export const ORDER_ADD_SUCCESS = 'ADD_ORDER';
export const ORDER_ADD_FAIL = 'ORDER_ADD_FAIL';

export const ORDER_UPDATE_REQUEST = 'ORDER_UPDATE_REQUEST';
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS';
export const ORDER_UPDATE_FAIL = 'ORDER_UPDATE_FAIL';

export const ORDER_DELETE_REQUEST = 'ORDER_DELETE_REQUEST';
export const ORDER_DELETE_SUCCESS = 'DELETE_ORDER';
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL';

export const CUSTOMER_ORDER_FETCH_REQUEST = 'CUSTOMER_ORDER_FETCH_REQUEST';
export const CUSTOMER_ORDER_FETCH_SUCCESS = 'CUSTOMER_ORDER_FETCH_SUCCESS';
export const CUSTOMER_ORDER_FETCH_FAIL = 'CUSTOMER_ORDER_FETCH_FAIL';


