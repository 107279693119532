import * as UserActions from "../actions/ActionTypes/UserTypes"

const initialState = {loading:false,users:[],error:false , count:0};


const UserReducer = (state = initialState , action) => {

  switch (action.type) {

    case UserActions.USER_FETCH_SUCCESS:
      return {
        ...state , users : action.users.users , loading: false , error: false, count: action.users.count
      }
    case UserActions.USER_FETCH_FAIL:
      return {
        ...state , users : [] , loading: false , error: true , count: 0
      }
    case UserActions.USER_FETCH_REQUEST:
      return {
        ...state , users : [] , loading: true , error: false , count: 0
      }

    case UserActions.USER_DELETE_SUCCESS:
      // const userList = state.users.filter(row=>row.id!=action.userId);

      const index = state.users.findIndex(row => row.id === action.userId);
      const modified = state.users;
      console.log(modified[index].status)
      modified[index].status = 2;
      console.log(modified[index].status)
      return {
        ...state, users: modified
      }
      return state;
    case 'UPDATE_USER':
      return state;
    case 'ADD_USER':
      return state;
    default:
      return state;
  }
}

export default UserReducer
