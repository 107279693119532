import * as CartActions from "../actions/ActionTypes/CartTypes"

const initialState = {loading: false, items: [], error: false, msg: ''};


const CartReducer = (state = initialState, action) => {

  switch (action.type) {

    case CartActions.CART_FETCH_SUCCESS:
      return {
        ...state, items: action.cart.cart, loading: false, error: false,
      }
    case CartActions.CART_FETCH_FAIL:
      return {
        ...state, items: [], loading: false, error: true,
      }
    case CartActions.CART_FETCH_REQUEST:
      return {
        ...state, items: [], loading: true, error: false,msg:''
      }
    case CartActions.CART_DELETE_REQUEST:
      return {
        ...state, loading: true, error: false, msg: ''
      }
    case CartActions.CART_DELETE_SUCCESS:
      const cartList = state.items.filter(row => row.id != action.cartId);
      return {
        ...state, items: cartList, loading: false,msg:''
      }
    case CartActions.CART_DELETE_FAIL:
      return {
        ...state, loading: false, error: true, msg: action.msg
      }
    case CartActions.CART_UPDATE_FAIL:
      return {
        ...state, loading: false, error: true, msg: action.msg
      }
    case CartActions.CART_UPDATE_SUCCESS:
      const index = state.items.findIndex(row => row.id === action.id);
      const modified = state.items;
      modified[index].quantity = action.qty;
      return {
        ...state, items: modified
      }
    case CartActions.CART_CHECKOUT_SUCCESS:
      return {
        ...state, loading: false, error: false,msg:''
      }
    case CartActions.CART_CHECKOUT_REQUEST:
      return {
        ...state, loading: true, error: false,msg:''
      }
    case CartActions.CART_CHECKOUT_FAIL:
      return {
        ...state, loading: false, error: true,msg: action.msg , items: state.items
      }
    case 'ADD_CART':
      return state;
    default:
      return state;
  }
}

export default CartReducer
