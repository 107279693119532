import * as CategoryActions from "../actions/ActionTypes/CategoryTypes"

const initialState = {loading:false,categories:[],error:false , count:0};


const CategoryReducer = (state = initialState , action) => {

  switch (action.type) {

    case CategoryActions.CATEGORY_FETCH_SUCCESS:
      // console.log(action)
      return {
        ...state , categories : action.category.categories , loading: false , error: false, count: action.category.count
      }
    case CategoryActions.CATEGORY_FETCH_FAIL:
      return {
        ...state , categories : [] , loading: false , error: true , count: 0
      }
    case CategoryActions.CATEGORY_FETCH_REQUEST:
      return {
        ...state , categories : [] , loading: true , error: false , count: 0
      }
     //
    case CategoryActions.CATEGORY_ADD_REQUEST:
      return {
        ...state , loading: true
      }
    case CategoryActions.CATEGORY_ADD_SUCCESS:
      return {
        ...state , loading: false , error: false
      }
    case CategoryActions.CATEGORY_ADD_FAIL:
      return {
        ...state , loading: false , error: true
      }
     //
    //
    case CategoryActions.CATEGORY_UPDATE_REQUEST:
      return {
        ...state , loading: true
      }
    case CategoryActions.CATEGORY_UPDATE_SUCCESS:
      return {
        ...state , loading: false , error: false
      }
    case CategoryActions.CATEGORY_UPDATE_FAIL:
      return {
        ...state , loading: false , error: true
      }
    //
    case CategoryActions.CATEGORY_DELETE_REQUEST:
      return {
        ...state , loading: true
      }
    case CategoryActions.CATEGORY_DELETE_FAIL:
      return {
        ...state , loading: false , error: true
      }
    case CategoryActions.CATEGORY_DELETE_SUCCESS:
      const userList = state.categories.filter(row=>row.id!=action.categoryId);
      return {
        ...state, categories: userList
      }
    default:
      return state;
  }
}

export default CategoryReducer
