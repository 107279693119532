export const USER_FETCH_REQUEST = 'USER_FETCH_REQUEST';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_FAIL = 'USER_FETCH_FAIL';

export const USER_ADD_REQUEST = 'USER_ADD_REQUEST';
export const USER_ADD_SUCCESS = 'ADD_USER';
export const USER_ADD_FAIL = 'USER_ADD_FAIL';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'DELETE_USER';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';


