const initialState = {
  sidebarShow: 'responsive',
  loading: false
}

const ChangeStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'set':
      return {...state, ...action }
    case 'show_loader':
      return {...state, loading: true }
    case 'hide_loader':
      return {...state, loading: false }
    default:
      return state
  }
}

export default ChangeStateReducer;


