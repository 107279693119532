import * as OrderActions from "../actions/ActionTypes/OrderTypes"

const initialState = {loading:false,orders:[],error:false,count:0};


const OrderReducer = (state = initialState , action) => {

  switch (action.type) {

    case OrderActions.ORDER_FETCH_SUCCESS:
      return {
        ...state , orders : action.orders.orders , loading: false , error: false , count: action.orders.count
      }
    case OrderActions.ORDER_FETCH_FAIL:
      return {
        ...state , orders : [] , loading: false , error: true
      }
    case OrderActions.ORDER_FETCH_REQUEST:
      return {
        ...state , orders : [] , loading: true , error: false
      }

    case 'DELETE_ORDER':
      const orderList = state.orders.filter(row=>row.id!=action.orderId);
      return {
        ...state, orders: orderList
      }
    case OrderActions.ORDER_UPDATE_SUCCESS:
      const index = state.orders.findIndex(row => row.id === action.id);
      const modified = state.orders;
      console.log(modified[index].status)
      modified[index].status = action.status;
      console.log(modified[index].status)
      return {
        ...state, orders: modified
      }
      return state;
    case 'ADD_ORDER':
      return state;
    default:
      return state;
  }
}

export default OrderReducer
