export const CART_FETCH_REQUEST = 'CART_FETCH_REQUEST';
export const CART_FETCH_SUCCESS = 'CART_FETCH_SUCCESS';
export const CART_FETCH_FAIL = 'CART_FETCH_FAIL';

export const CART_ADD_REQUEST = 'CART_ADD_REQUEST';
export const CART_ADD_SUCCESS = 'ADD_CART';
export const CART_ADD_FAIL = 'CART_ADD_FAIL';

export const CART_UPDATE_REQUEST = 'CART_UPDATE_REQUEST';
export const CART_UPDATE_SUCCESS = 'CART_UPDATE_SUCCESS';
export const CART_UPDATE_FAIL = 'CART_UPDATE_FAIL';

export const CART_DELETE_REQUEST = 'CART_DELETE_REQUEST';
export const CART_DELETE_SUCCESS = 'DELETE_CART';
export const CART_DELETE_FAIL = 'CART_DELETE_FAIL';

export const CART_CHECKOUT_REQUEST = 'CART_CHECKOUT_REQUEST';
export const CART_CHECKOUT_SUCCESS = 'CART_CHECKOUT_SUCCESS';
export const CART_CHECKOUT_FAIL = 'CART_CHECKOUT_FAIL';





