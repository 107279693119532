import * as ProductActions from "../actions/ActionTypes/ProductTypes"

const initialState = {loading:false,products:[],error:false , count:0};


const ProductReducer = (state = initialState , action) => {

  switch (action.type) {

    case ProductActions.PRODUCT_FETCH_SUCCESS:
      return {

        ...state , products : action.products.products , loading: false , error: false, count: action.products.count
      }
    case ProductActions.PRODUCT_FETCH_FAIL:
      return {
        ...state , products : [] , loading: false , error: true , count: 0
      }
    case ProductActions.PRODUCT_FETCH_REQUEST:
      return {
        ...state , products : [] , loading: true , error: false , count: 0
      }

    case 'DELETE_PRODUCT':
      // console.log("deleting")
      // console.log(state.products);
      const productList = state.products.filter(row=>row.id!=action.productId);
      // console.log(productList)
      return {
        ...state, products: productList
      }
    case 'UPDATE_PRODUCT':
      return {...state , loading: false};
    case 'UPDATE_PRODUCT_PRICE':
      const rowIndex = state.products.findIndex(row => row.id === action.id);
      const modified = state.products;
      switch (action.ptype) {
        case 'pp':
          modified[rowIndex]['purchase_price'] = action.value;
          break;
        case 'sp':
          modified[rowIndex]['sale_price'] = action.value;
          break;
        case 'dp':
          modified[rowIndex]['discounted_price'] = action.value;
          break;
        default:
          console.log("No value found")
      }
      return {...state , loading: false};
    case 'ADD_PRODUCT':
      return {...state , loading: false};
    default:
      return state;
  }
}

export default ProductReducer
