import * as ActiveUsers from '../actions/ActionTypes/ActiveUserTypes'
const initialState = {loading:false,users:[],error:false,count:0};
const ActiveInactiveReducer = (state = initialState , action) => {
  switch (action.type) {
    case ActiveUsers.FETCH_ACTIVE_INACTIVE_USERS:
      return {
        ...state , users : [] , loading: true , error: false , count:0

      }
    case ActiveUsers.SET_ACTIVE_INACTIVE_USERS:
      return {
        ...state , users : action.users , loading: false , error: false, count:action.count
      }
    case ActiveUsers.SET_ACTIVE_INACTIVE_USERS_FAIL:
      return {
        ...state , users : [] , loading: false , error: true , count:0
      }
    default:
      return state;
  }
}
export default ActiveInactiveReducer;
