import * as dTypes from '../actions/ActionTypes/DashboardType'

const initalState = {

  loadingProduct:false,
  loadingProductError:false,
  productsCount:0,
  loadingProductViews:false,
  loadingProductViewsError:false,
  productsViewsCount:0,
  productsViewsLeftCount:0,
  loadingActiveProduct:false,
  loadingActiveProductError:false,
  productsActiveCount:0,
 loadingMostViewedProduct:false,
  loadingMostViewedProductError:false,
  mostViewedProductList:[],

}

const DashboardReducer = (state = initalState,action) => {

  switch (action.type) {


    case dTypes.FETCH_PRODUCTS_COUNT:
      return {...state , loadingProduct:true ,productsCount: 0 ,loadingProductError:false }
    case dTypes.SET_PRODUCTS_COUNT:
      return {...state , loadingProduct:false ,productsCount: action.count,loadingProductError:false}
    case dTypes.SET_PRODUCTS_FAIL:
      return {...state , loadingProduct:false ,productsCount: 0,loadingProductError:true}

    case dTypes.FETCH_VIEWS_COUNT:
      return {...state , loadingProductViews:true ,productsViewsCount: 0,productsViewsLeftCount: 0 ,loadingProductViewsError:false }
    case dTypes.SET_VIEWS_COUNT:
      return {...state , loadingProductViews:false ,productsViewsCount: action.count,productsViewsLeftCount: action.left,loadingProductViewsError:false}
    case dTypes.SET_VIEWS_FAIL:
      return {...state , loadingProductViews:false ,productsViewsCount: 0,productsViewsLeftCount: 0,loadingProductViewsError:true}

    case dTypes.FETCH_ACTIVE_PRODUCTS_COUNT:
      return {...state , loadingActiveProduct:true ,productsActiveCount: 0 ,loadingActiveProductError:false }
    case dTypes.SET_ACTIVE_PRODUCTS_COUNT:
      return {...state , loadingActiveProduct:false ,productsActiveCount: action.count,loadingActiveProductError:false}
    case dTypes.SET_ACTIVE_PRODUCTS_FAIL:
      return {...state , loadingActiveProduct:false ,productsActiveCount: 0,loadingActiveProductError:true}

    case dTypes.FETCH_MOST_VIEWED_PRODUCTS_COUNT:
      return {...state , loadingMostViewedProduct:true ,mostViewedProductList: [] ,loadingMostViewedProductError:false }
    case dTypes.SET_MOST_VIEWED_PRODUCTS_COUNT:
      return {...state , loadingMostViewedProduct:false ,mostViewedProductList: action.products,loadingMostViewedProductError:false}
    case dTypes.SET_MOST_VIEWED_PRODUCTS_FAIL:
      return {...state , loadingMostViewedProduct:false ,mostViewedProductList: [],loadingMostViewedProductError:true}
    default:
      return state;
  }
}

export default DashboardReducer;
