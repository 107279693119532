import * as ProductActions from "../actions/ActionTypes/ProductTypes"

const initialState = {products: [], error: false, msg: '',loading:false, loadLoading: false, loadMsg: '', fileUploadsError:[]};


const ProductUploadsReducer = (state = initialState, action) => {

  switch (action.type) {
    case ProductActions.PRODUCT_UPLOAD_REQUEST:
      return {
        ...state, products: [], loading: true, error: false, msg: '', loadLoading: false,  loadMsg: '' , fileUploadsError:[],
      }
    case ProductActions.PRODUCT_UPLOAD_SUCCESS:
      return {
        ...state,
        products : action.products,
        loading: false,
        error: false,
        msg: 'Upload Success' ,
        fileUploadsError:action.uploadError,
        loadLoading: false, loadMsg: '',
      }
    case ProductActions.PRODUCT_UPLOAD_FAIL:
      return {
        ...state,
        products : [],
        loading: false,
        error: true,
        msg: action.msg,
        loadLoading: false,
        fileUploadsError:[],
        loadMsg: '',
      }
    case ProductActions.PRODUCT_UPLOAD_CLEAR:
      return {
        ...state, products: [], loading: false, error: false, msg: '', loadLoading: false,  loadMsg: '' , fileUploadsError:[],
      }
    default :
      return state;
  }
}

export default ProductUploadsReducer
