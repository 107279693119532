
import * as RegisterActions from '../actions/ActionTypes/RegisterTypes';
const initialState = {registered: false, loading: false, msg: '',error:false ,
  forgot: false, loadingForgot: false, msgForgot: '',errorForgot:false,
  changePassword: false, loadingChangePassword: false, msgChangePassword: '',errorChangePassword:false

};
const RegisterReducer = (state = initialState, action) => {

  switch (action.type) {

    case RegisterActions.USER_REGISTER_REQUEST:
      // // console.log(action)
      return {
        ...state, registered : false,  loading: true , msg: '' , error: false
      }
    case RegisterActions.USER_REGISTER_SUCCESS:
      // // console.log(action)
      return {
        ...state, registered : true,  loading: false , msg: '', error: false
      }
    case RegisterActions.USER_REGISTER_FAIL:
      return {
        ...state, registered : false, loading : false, msg : action.msg, error: true
      }
    case RegisterActions.USER_REGISTER_CLEAR:
      return {
        ...state, registered : false, loading : false, msg : '', error: false
      }

    case RegisterActions.USER_VALIDATE_TOKEN_REQUEST:
      // // console.log(action)
      return {
        ...state, forgot : false,  loadingForgot: true , msgForgot: '' , errorForgot: false
      }
    case RegisterActions.USER_VALIDATE_TOKEN_SUCCESS:
      // // console.log(action)
      return {
        ...state, forgot : true,  loadingForgot: false , msgForgot: '', errorForgot: false
      }
    case RegisterActions.USER_VALIDATE_TOKEN_FAIL:
      return {
        ...state, forgot : false, loadingForgot : false, msgForgot : action.msg, errorForgot: true
      }
    case RegisterActions.USER_VALIDATE_TOKEN_CLEAR:
      return {
        ...state, forgot : false, loadingForgot : false, msgForgot : '', errorForgot: false
      }
    case RegisterActions.USER_CHANGE_PASSWORD_REQUEST:
      // // console.log(action)
      return {
        ...state, changePassword: false, loadingChangePassword: true, msgChangePassword: '',errorChangePassword:false
      }
    case RegisterActions.USER_CHANGE_PASSWORD_SUCCESS:
      // // console.log(action)
      return {
        ...state, changePassword: true, loadingChangePassword: false, msgChangePassword:'',errorChangePassword:false
      }
    case RegisterActions.USER_CHANGE_PASSWORD_FAIL:
      return {
        ...state, changePassword: false, loadingChangePassword: false, msgChangePassword: action.msg,errorChangePassword:true
      }
    case RegisterActions.USER_CHANGE_PASSWORD_CLEAR:
      return {
        ...state, changePassword: false, loadingChangePassword: false, msgChangePassword: '',errorChangePassword:false
      }
    default:
      return  state;
  }
}

export default RegisterReducer
