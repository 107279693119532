
import * as StockActions from "../actions/ActionTypes/StockTypes"

const initialState = {loading:false,stock:[],error:false , count:0};


const StockReducer = (state = initialState , action) => {

  switch (action.type) {

    case StockActions.STOCK_FETCH_SUCCESS:
      // console.log(action)
      return {
        ...state , stock : action.stock.stock , loading: false , error: false, count: action.stock.count
      }
    case StockActions.STOCK_FETCH_FAIL:
      return {
        ...state , stock : [] , loading: false , error: true , count: 0
      }
    case StockActions.STOCK_FETCH_REQUEST:
      return {
        ...state , stock : [] , loading: true , error: false , count: 0
      }
    //
    case StockActions.STOCK_ADD_REQUEST:
      return {
        ...state , loading: true
      }
    case StockActions.STOCK_ADD_SUCCESS:
      return {
        ...state , loading: false , error: false
      }
    case StockActions.STOCK_ADD_FAIL:
      return {
        ...state , loading: false , error: true
      }
    //
    //
    case StockActions.STOCK_UPDATE_REQUEST:
      return {
        ...state , loading: true
      }
    case StockActions.STOCK_UPDATE_SUCCESS:
      return {
        ...state , loading: false , error: false
      }
    case StockActions.STOCK_UPDATE_FAIL:
      return {
        ...state , loading: false , error: true
      }
    //
    case StockActions.STOCK_DELETE_REQUEST:
      return {
        ...state , loading: true
      }
    case StockActions.STOCK_DELETE_FAIL:
      return {
        ...state , loading: false , error: true
      }
    case StockActions.STOCK_DELETE_SUCCESS:
      const stockList = state.stock.filter(row=>row.id!=action.stockId);
      return {
        ...state, stock: stockList
      }
    default:
      return state;
  }
}

export default StockReducer
