export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTER_CLEAR = 'USER_REGISTER_CLEAR';

export const USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_FAIL = 'USER_CHANGE_PASSWORD_FAIL';
export const USER_CHANGE_PASSWORD_CLEAR = 'USER_CHANGE_PASSWORD_CLEAR';

export const USER_VALIDATE_TOKEN_REQUEST = 'USER_VALIDATE_TOKEN_REQUEST';
export const USER_VALIDATE_TOKEN_SUCCESS = 'USER_VALIDATE_TOKEN_SUCCESS';
export const USER_VALIDATE_TOKEN_FAIL = 'USER_VALIDATE_TOKEN_FAIL';
export const USER_VALIDATE_TOKEN_CLEAR = 'USER_VALIDATE_TOKEN_CLEAR';


