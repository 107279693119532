export const SUBSCRIPTION_FETCH_REQUEST = 'SUBSCRIPTION_FETCH_REQUEST';
export const SUBSCRIPTION_FETCH_SUCCESS = 'SUBSCRIPTION_FETCH_SUCCESS';
export const SUBSCRIPTION_FETCH_FAIL = 'SUBSCRIPTION_FETCH_FAIL';

export const SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_ADD_REQUEST';
export const SUBSCRIPTION_SUCCESS = 'ADD_SUBSCRIPTION';
export const SUBSCRIPTION_FAIL = 'SUBSCRIPTION_ADD_FAIL';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';
