export const PRODUCT_FETCH_REQUEST = 'PRODUCT_FETCH_REQUEST';
export const PRODUCT_FETCH_SUCCESS = 'PRODUCT_FETCH_SUCCESS';
export const PRODUCT_FETCH_FAIL = 'PRODUCT_FETCH_FAIL';

export const PRODUCT_ADD_REQUEST = 'PRODUCT_ADD_REQUEST';
export const PRODUCT_ADD_SUCCESS = 'ADD_PRODUCT';
export const PRODUCT_ADD_FAIL = 'PRODUCT_ADD_FAIL';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'DELETE_PRODUCT';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';


export const PRODUCT_UPLOAD_REQUEST = 'PRODUCT_UPLOAD_REQUEST';
export const PRODUCT_UPLOAD_SUCCESS = 'PRODUCT_UPLOAD_SUCCESS';
export const PRODUCT_UPLOAD_FAIL = 'PRODUCT_UPLOAD_FAIL';
export const PRODUCT_UPLOAD_CLEAR = 'PRODUCT_UPLOAD_CLEAR';
