import {persistStore,persistReducer} from "redux-persist";

import RootReducer from "./reducers/RootReducer";

import {createStore ,applyMiddleware} from "redux";
import thunk from "redux-thunk";

// const persistConfig = {
//     key: 'root',
//     storage
// }
// const persistedReducer = persistReducer(persistConfig,combineReducer)

// let store = createStore(persistedReduer,applyMiddleware(thunk))
// export const  persistor = persistStore(store)

// export default () => {
//
//     return { store,persistor}
// }

 const store = createStore(RootReducer,applyMiddleware(thunk));
 const persistor = persistStore(store);

export  {store,persistor}
