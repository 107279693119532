
import * as ListFilesActions from "../actions/ActionTypes/UploadedFiles"

const initialState = {loading:false,files:[],error:false , count:0};


const UploadedFilesReducer = (state = initialState , action) => {

  switch (action.type) {

    case ListFilesActions.UPLOADED_FILES_FETCH_SUCCESS:
      // console.log(action)
      return {
        ...state , files : action.files.files , loading: false , error: false, count: action.files.count
      }
    case ListFilesActions.UPLOADED_FILES_FETCH_FAIL:
      return {
        ...state , files : [] , loading: false , error: true , count: 0
      }
    case ListFilesActions.UPLOADED_FILES_FETCH_REQUEST:
      return {
        ...state , files : [] , loading: true , error: false , count: 0
      }
    default:
      return state;
  }
}

export default UploadedFilesReducer
